<template>
  <div class="topbar" data-aos="fade-in">
    <div class="container topbar__content-wrapper">
      <h1>
        <router-link :to="{ name: 'homepage' }" class="name">
          <span class="name__start"> Schizofrenie </span>
          <span class="name__finish"> a rodina </span>
        </router-link>
      </h1>

      <div :class="{ hamburger: true, open: isMenuOpen }" @click="isMenuOpen = !isMenuOpen" ref="hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav :class="{ menu: true, open: isMenuOpen }" ref="menu">
        <router-link v-for="(item, i) in menuItems" :key="i" :to="item.url" class="item" @click="isMenuOpen = false">
          {{ item.text }}
        </router-link>
        <router-link :to="{ name: 'homepage', hash: '#objednat-knihu' }" class="highlighted-item" @click="isMenuOpen = false">
          <span class="highlighted-item__wrapper">
            <span class="highlighted-item__icon"> <IconFontAwesome name="basket-shopping"></IconFontAwesome> </span>
            <span class="highlighted-item__text"> Objednat knihu </span>
          </span>
        </router-link>
      </nav>
    </div>
  </div>

  <main class="main-content-wrapper">
    <router-view />
  </main>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__copy">Copyright &copy; {{ currentYear }}</div>
      <a href="https://infinitedesign.cz" target="_blank" class="studio">
        <span class="studio__created-text"> Vytvořeno&nbsp;v </span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.07 113.39" class="studio__logo">
          <path d="M107.83,43.28A19,19,0,0,1,81,70.09L67.61,56.69,81,43.28a19,19,0,0,1,26.81,0M43.29,32.37l13.4,13.4L70.1,32.37a34.4,34.4,0,0,1,32.39-9.11A56.63,56.63,0,0,0,56.7,0H11.34A11.34,11.34,0,0,0,0,11.34H0V28a34.45,34.45,0,0,1,43.29,4.38M5.56,43.28A19,19,0,0,0,19.07,75.64a19.44,19.44,0,0,0,13.57-5.82L45.78,56.69,32.37,43.28a19,19,0,0,0-26.81,0M94.42,91.06A34.3,34.3,0,0,1,70.1,81L56.69,67.6,43.29,81A34.4,34.4,0,0,1,0,85.38v16.67a11.34,11.34,0,0,0,11.34,11.34H56.7A56.63,56.63,0,0,0,102.5,90.1a34.38,34.38,0,0,1-8.08,1" transform="translate(0 0)" />
          <path d="M140.7,87.53V68.82m1.37,1.29V86.3" transform="translate(0 0)" />
        </svg>
        <span class="studio__name"> Infinite Design </span>
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconFontAwesome from "./components/IconFontAwesome.vue";
import { onClickOutside } from "@vueuse/core";

const menuItems = [
  { text: "O knize", url: { name: "homepage", hash: "#o-knize" } },
  { text: "O autorovi", url: { name: "homepage", hash: "#o-autorovi" } },
  { text: "Reference", url: { name: "homepage", hash: "#reference" } },
];

const currentYear = new Date().getFullYear();

const isMenuOpen = ref(false);
const menu = ref(null);
const hamburger = ref(null);
onClickOutside(menu, () => (isMenuOpen.value = false), {
  ignore: [hamburger],
});
</script>

<style lang="scss" scoped>
.footer {
  background: #014d51;
  padding: 25px 0;
  color: #fff;
  font-family: var(--primary-font);
  font-size: 0.9rem;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.menu {
  display: flex;
  align-items: center;
  gap: 64px;
  transition: transform 0.2s ease;
  .item {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.935rem;
    font-weight: 400;
    font-family: var(--primary-font);
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1.1;
    padding: 6px 3px;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 1px;
      width: 0;
      display: block;
      transition: all 0.3s ease-out;
      background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    }
    &:hover {
      text-decoration: none;
      &:after {
        left: 0;
        width: 100%;
      }
    }
  }

  .highlighted-item {
    position: relative;
    border-radius: var(--radius);
    font-weight: 700;
    font-family: var(--primary-font);
    color: #fff;
    overflow: hidden;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 13px;
      min-width: 200px;
      height: 50px;
      position: relative;
      border-radius: var(--radius);
      z-index: 3;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to right, var(--secondary-color) 0%, var(--primary-color) 100%);
      border-radius: var(--radius);
      display: block;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--radius);
      transition: opacity 0.5s ease;
      opacity: 0;
      background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color) 100%);
      z-index: 2;
    }
    &:hover {
      text-decoration: none;
      &:after {
        opacity: 1;
      }
    }
    &__icon {
      --size: 20px;
      width: var(--size);
      height: var(--size);
      color: #fff;
      display: block;
      svg {
        width: 100%;
        height: 100%;
        * {
          fill: currentColor;
        }
      }
    }
    &:hover {
      text-decoration: none;
    }
  }

  @include for-size(xl) {
    gap: 30px;
  }

  @include for-size(lg) {
    gap: 20px;
    &__item {
      font-size: 14px;
    }
  }

  @include for-size(md) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    display: block;
    transform: translateX(100%);
    background: var(--primary-color);
    padding: var(--topbar-height) 30px 0 30px;
    min-width: 200px;
    .item {
      height: auto;
      width: 100%;
      padding: 10px 0;
      font-size: 22px;
      color: #fff;
      display: block;
      font-size: 1rem;
    }
    .highlighted-item {
      background: var(--secondary-color);
      display: block;
      margin-top: 30px;
      &:before {
        display: none;
      }
    }
  }
  &.open {
    transform: translateX(0);
  }
}

.topbar {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #fff;

  &__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--topbar-height);
    transition: height 0.3s ease;
  }

  .name {
    font-family: var(--secondary-font);
    font-style: italic;
    font-weight: 700;
    font-size: 1.87rem;
    &__start {
      color: var(--secondary-color);
    }
    &__finish {
      color: var(--primary-color);
    }
    &:hover {
      text-decoration: none;
    }
    @include for-size(md) {
      font-size: 1.5rem;
    }
  }
}

.sticky .topbar {
  border-bottom-color: var(--primary-color);
  .name {
    padding-bottom: 0;
  }
}

.hamburger {
  --width: 30px;
  --height: 33px;
  --padding: 7px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: var(--width);
  height: var(--height);
  padding: var(--padding) 0;
  display: none;
  cursor: pointer;
  z-index: 999;
  color: var(--primary-color);
  transition: transform 0.3s ease;
  span {
    cursor: pointer;
    display: block;
    height: 1px;
    background: currentColor;
    transition: width 0.3s ease-out, opacity 0.35s ease, transform 0.3s ease-out, background 0.3s ease;
    width: 100%;
  }
  &:hover {
    color: #333;
  }

  @include for-size(md) {
    display: flex;
  }

  &.open span {
    width: calc(var(--width) * 0.8);
    --shift: calc(var(--height) / 3.8);
    color: #fff;
    &:nth-of-type(1) {
      transform: translate(0, calc(1 * var(--shift))) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translate(0, calc(-1 * var(--shift))) rotate(45deg);
    }
  }
}

.studio {
  --logo-width: 30px;
  --speed: 0.4s;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;

  &__created-text {
    display: block;
    overflow: hidden;
    width: 100px;
    text-align: right;
    transition: width var(--speed) ease-in-out;
  }
  &__logo {
    width: var(--logo-width);
    height: 26px;
    margin-left: 5px;
    display: block;
    * {
      fill: #fff;
    }
  }
  &__name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    transition: width var(--speed) ease-in-out;
  }
  &:hover {
    text-decoration: none;
    .studio__name {
      width: 125px;
    }
    .studio__created-text {
      width: 0;
    }
  }
}
</style>
