<template>
  <div class="icon">
    <FontAwesomeIcon :icon="iconParams" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faBasketShopping, faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

library.add(faBasketShopping, faMagnifyingGlass);

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    weight: {
      type: String,
      default: "solid",
      validator: function (value) {
        return ["light", "regular", "solid", "brands"].includes(value);
      },
    },
    brand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconParams() {
      if (this.brand) {
        return "fa-brands fa-" + this.name;
      } else {
        return "fa-" + this.weight + " fa-" + this.name;
      }
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100%;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    * {
      transition: fill 0.3s ease;
    }
  }
}
</style>
