import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "homepage",
    path: "/",
    component: () => import("@/views/PublicHomepage.vue"),
  },
  {
    name: "gdpr",
    path: "/zpracovani-osobnich-udaju",
    component: () => import("@/views/Gdpr.vue"),
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [...routes],
  scrollBehavior(to) {
    if (to.hash && document.getElementById(to.hash.slice(1))) {
      return { el: to.hash };
    }

    return { top: 0 };
  },
});
